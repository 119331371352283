import { catchError, tap } from 'rxjs/operators';
import { GlobalMessageService, GlobalMessageType } from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { RrsNewsletterAdapter } from './rrs-newsletter.adapter';

@Injectable({
  providedIn: 'root',
})
export class RrsNewsletterService {
  constructor(
    protected newsletterAdapter: RrsNewsletterAdapter,
    protected globalMessageService: GlobalMessageService
  ) {}

  signUpForNewsletter(email: string, zipcode: string): Observable<void> {
    return this.newsletterAdapter.singupForNewsletter(email, zipcode).pipe(
      tap(() => {
        this.globalMessageService.add(
          { key: 'rrs.forms.newsletter.successNotification' },
          GlobalMessageType.MSG_TYPE_CONFIRMATION,
          5000
        );
      }),
      catchError(() => {
        this.globalMessageService.add(
          { key: 'rrs.forms.newsletter.errorNotification' },
          GlobalMessageType.MSG_TYPE_ERROR,
          5000
        );
        return of(undefined);
      })
    );
  }
}
