import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';
import { ZIPCODE_LENGTH } from '@app/shared/configs/zipcode-input.config';

@Directive({
  selector: '[rrsZipcodeInput]',
})
export class ZipcodeInputDirective {
  constructor(public ngControl: NgControl) {}

  @HostListener('ngModelChange', ['$event'])
  onModelChange(value: string): void {
    this.onInputChange(value);
  }

  onInputChange(value: string): void {
    const cleanedValue =
      value?.replace(/\D/g, '').slice(0, ZIPCODE_LENGTH) ?? '';

    this.ngControl.valueAccessor?.writeValue(cleanedValue);
  }
}
