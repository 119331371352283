import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CmsComponentData, CustomFormValidators } from '@spartacus/storefront';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Contentstack } from '@app/custom/features/rrs-cms/models/cms-contentstack.model';
import { RrsNewsletterService } from '@app/shared/services/rrs-newsletter.service';
import { take } from 'rxjs/operators';
import { ZIPCODE_LENGTH } from '@app/shared/configs/zipcode-input.config';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'rrs-cms-footer',
  styleUrls: ['./rrs-cms-footer.component.scss'],
  templateUrl: './rrs-cms-footer.component.html',
})
export class RrsCmsFooterComponent implements OnInit {
  data$ = this.componentData.data$;

  newsletterForm!: UntypedFormGroup;

  constructor(
    protected componentData: CmsComponentData<Contentstack.Footer>,
    protected fb: UntypedFormBuilder,
    protected newsletterService: RrsNewsletterService
  ) {}

  ngOnInit(): void {
    this.newsletterForm = this.fb.group({
      email: ['', [Validators.required, CustomFormValidators.emailValidator]],
      zipcode: [
        '',
        {
          validators: [
            Validators.required,
            Validators.minLength(ZIPCODE_LENGTH),
            Validators.maxLength(ZIPCODE_LENGTH + 1),
          ],
        },
      ],
    });
  }

  get emailControl(): UntypedFormControl {
    return this.newsletterForm.get('email') as UntypedFormControl;
  }

  get zipcodeControl(): UntypedFormControl {
    return this.newsletterForm.get('zipcode') as UntypedFormControl;
  }

  submitNewsletter(): void {
    if (!this.newsletterForm.valid) {
      this.newsletterForm.markAllAsTouched();
      return;
    }

    const email = this.emailControl.value;
    const zipcode = this.zipcodeControl.value;

    this.newsletterService
      .signUpForNewsletter(email, zipcode)
      .pipe(take(1))
      .subscribe(() => this.newsletterForm.reset());
  }
}
