<article class="row py-4 cart-item">
  <div class="col-12 col-lg-6">
    <div class="row">
      <div class="col d-flex flex-column">
        <div *ngIf="!isDonation" class="d-flex flex-wrap">
          <a
            *ngFor="let badge of badges"
            cxModal="dismiss"
            [routerLink]="[badge.categoryUrl]">
            <span
              [style.background-color]="badge.bgColor"
              [style.color]="badge.textColor"
              [style.border]="badge.border"
              class="badge"
              >{{ badge.label }}</span
            >
          </a>
        </div>
        <a
          class="cart-item__imgs mb-2"
          [routerLink]="['/p', item.product?.baseProduct]"
          [attr.aria-label]="item.product?.name">
          <rrs-media
            [container]="item.product?.images?.PRIMARY"
            [alt]="item.product?.name"
            height="132"
            width="192"
            format="cartIcon"></rrs-media>
        </a>
        <rrs-add-to-wishlist
          *ngIf="!isDonation && !options.isSavedCart"
          [product]="item.product"></rrs-add-to-wishlist>
      </div>
      <div *ngIf="item.product" class="col">
        <ng-container *ngIf="!isDonation">
          <p class="mb-0 fw-bold">{{ item.product.brand }}</p>
        </ng-container>

        <a
          class="mb-0 fw-bold pe-2"
          [routerLink]="['/p', item.product?.baseProduct]"
          >{{ item.product?.name }}
        </a>

        <ng-container *ngIf="!isDonation">
          <p
            class="mb-0 text-uppercase"
            *ngIf="!item.product?.isProductWithoutSize">
            {{ item.product.size }}&nbsp;{{ item.product.width }}
          </p>
          <p class="mb-0 text-uppercase">{{ item.product.colorPrimary }}</p>
        </ng-container>

        <p class="mb-0 text-uppercase">
          {{ 'cart.sku' | cxTranslate }}:
          {{ item.product?.baseProduct }}
        </p>
      </div>
    </div>
  </div>

  <div class="col-3 d-none d-lg-block">
    <ng-container *ngIf="!isDonation">
      <!-- TODO: Cart Item Shipping Information -->
      <ng-container
        [ngTemplateOutlet]="fulfillmentTitle"
        [ngTemplateOutletContext]="{ entry: item }"></ng-container>
      <ng-container
        [ngTemplateOutlet]="fulfillmentDetails"
        [ngTemplateOutletContext]="{ entry: item }"></ng-container>
    </ng-container>
  </div>

  <div class="col-12 col-lg-2">
    <div class="d-flex flex-column align-items-center">
      <p class="fs-4 mb-2 ms-auto ms-lg-0">
        {{ item.basePrice?.formattedValue }}
      </p>

      <ng-container *ngIf="!isDonation">
        <ng-container *ngFor="let promotion of productPromotions">
          <div
            class="fs-light fs-7 p-1 d-flex flex-column align-items-center justify-content-center mb-2 cart-item__promotion w-100">
            <p class="mb-1">{{ promotion?.description }}</p>
            <p class="mb-0">
              -{{
                item.basePrice.value - promotion.adjustedUnitPrice | currency
              }}
            </p>
          </div>
        </ng-container>
      </ng-container>

      <div class="hr mb-2"></div>
      <p class="fs-7 mb-0">{{ 'cart.itemTotal' | cxTranslate }}</p>
      <p class="fs-4 fw-bold mb-0">
        {{ item.totalPrice?.formattedValue }}
      </p>
    </div>
  </div>

  <div class="cart-item__remove col-1 text-center">
    <button
      (click)="removeItem()"
      [attr.aria-label]="'cart.removeItem' | cxTranslate"
      class="btn btn-close"></button>
  </div>

  <ng-container *ngIf="!isDonation">
    <rrs-accordion [collapsing]="true" class="accordion--cart py-2 d-lg-none">
      <accordion-item>
        <ng-template accordionHeader let-toggle="toggle">
          <div
            (click)="toggle()"
            (keydown.enter)="toggle()"
            tabindex="0"
            class="d-flex align-items-center justify-content-between py-2">
            <h6 class="m-0">{{ 'cart.viewItemDetails' | cxTranslate }}</h6>
            <rrs-icon
              class="accordion-icon"
              [icon]="iconTypeList.CHEVRON_DOWN"
              [size]="12"></rrs-icon>
          </div>
        </ng-template>

        <ng-template accordionContent>
          <div class="row py-2">
            <div class="col">
              <p class="fw-bold mb-1">{{ 'cart.fulfillment' | cxTranslate }}</p>
              <ng-container
                [ngTemplateOutlet]="fulfillmentTitle"
                [ngTemplateOutletContext]="{ entry: item }"></ng-container>
            </div>
            <div class="col">
              <p class="fw-bold mb-1">{{ 'cart.location' | cxTranslate }}</p>
              <ng-container
                [ngTemplateOutlet]="fulfillmentDetails"
                [ngTemplateOutletContext]="{ entry: item }"></ng-container>
            </div>
          </div>
        </ng-template>
      </accordion-item>
    </rrs-accordion>
  </ng-container>
</article>

<ng-template #fulfillmentTitle let-entry="entry">
  <p class="mb-0">
    {{
      (entry.deliveryPointOfService ? 'cart.pickupFrom' : 'cart.shipTo')
        | cxTranslate
    }}
  </p>
</ng-template>

<ng-template #fulfillmentDetails let-entry="entry">
  <!-- Item is for pickup -->
  <ng-container *ngIf="entry.deliveryPointOfService; else deliveryItem">
    <p class="mb-0">
      {{ entry.deliveryPointOfService.displayName }} <br />
      {{ entry.deliveryPointOfService.address?.line1 }} <br />
      {{ entry.deliveryPointOfService.address?.town }},
      {{ entry.deliveryPointOfService.address?.region?.isocodeShort }}
      {{ entry.deliveryPointOfService.address?.postalCode }} <br />
      {{ entry.deliveryPointOfService.phoneNumber | rrsPhoneNumber }}
      <rrs-store-logo
        class="d-block"
        [store]="entry.deliveryPointOfService"
        [size]="56"></rrs-store-logo>
    </p>
  </ng-container>
  <!-- Item is for delivery -->
  <ng-template #deliveryItem>
    <p class="mb-0">
      <ng-container *ngIf="deliveryAddress; else noAddress">
        {{ deliveryAddress?.firstName }}&nbsp;{{ deliveryAddress?.lastName }}
        <br />
        {{ deliveryAddress?.line1 }} <br />
        <span *ngIf="deliveryAddress?.line2"
          >{{ deliveryAddress.line2 }} <br
        /></span>
        {{ deliveryAddress?.town }}&nbsp;{{
          deliveryAddress?.region?.isocodeShort
        }}&nbsp;{{ deliveryAddress?.postalCode }}
      </ng-container>
      <ng-template #noAddress>
        {{ 'cart.itemsToBeDelivered' | cxTranslate }}
      </ng-template>
    </p>
  </ng-template>
</ng-template>
