import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RrsNewsletterAdapter {
  constructor(private http: HttpClient) {}

  singupForNewsletter(email: string, zipcode: string): Observable<any> {
    const url = `https://y5m85tnzwb.execute-api.us-east-1.amazonaws.com/production/signupEmail?user_valid=&processor=https%3A%2F%2Fy5m85tnzwb.execute-api.us-east-1.amazonaws.com%2Fproduction%2FsignupEmail&email2=${email}&Zipcode=${zipcode}`;

    return this.http.get(url).pipe(catchError(() => of(true)));
  }
}
